import React from "react";
import * as Yup from "yup";
import {
  AFCheckbox,
  AFDropdown,
  AFFormModal,
  AFTextField,
} from "sharedComponents/Form";
import { useUpdateRuntime } from "hooks/runtime/useUpdateRuntime";
import RequiredLabel from "sharedComponents/RequiredLabel";

function RuntimeEditModal({
  isEditModalOpen,
  setIsEditModalOpen,
  selectedData,
}) {
  const selectedRowData = selectedData;
  const [isManagedByPlatform, setIsManagedByPlatform] = React.useState(false);
  const [clusterSizes, setClusterSizes] = React.useState([]);

  React.useEffect(() => {
    setIsManagedByPlatform(
      selectedData.managedByPlatform === "Yes" ? true : false
    );
    setClusterSizes(
      selectedData.clusterSizes.map((size) => ({
        label: size,
        value: size,
      }))
    );
  }, [selectedData]);

  const { mutateAsync: updateRuntime, isLoading: isUpdateRuntimeLoading } =
    useUpdateRuntime();

  const runtimeValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    awsClusterId: Yup.string().required(),
    clusterSize: isManagedByPlatform && Yup.object().required(),
  });

  const handleUpdateRuntime = async (formData) => {
    console.log("formData", formData);
    const runtimeValues = {
      name: formData.name,
      awsClusterId: formData.awsClusterId || "",
      managedByPlatform: isManagedByPlatform,
      clusterSize: isManagedByPlatform ? formData.clusterSize?.value || "" : "", // Set to "" when not managed by platform
      isJupyterEnabled: false,
    };

    await updateRuntime({
      runtimeValues: runtimeValues,
      runtimeId: selectedRowData.id,
    });
    setIsEditModalOpen(false);
  };

  const initialValues = {
    name: selectedRowData.name,
    awsClusterId: selectedRowData.awsClusterId,
    hostUrl: selectedRowData.hostUrl,
    managedByPlatform:
      selectedRowData.managedByPlatform === "Yes" ? true : false,
    idleTimeout: selectedRowData?.idleTimeout || 0,
    clusterSize: clusterSizes.find(
      (data) => data?.label === selectedRowData?.clusterSize
    ),
    clusterSizes: selectedRowData.clusterSizes,
    isJupyterEnabled: selectedRowData.isJupyterEnabled === "Yes" ? true : false,
  };

  return (
    <AFFormModal
      onClose={() => setIsEditModalOpen(false)}
      onSubmit={handleUpdateRuntime}
      validationSchema={runtimeValidationSchema}
      initialValues={initialValues}
      isOpen={isEditModalOpen}
      title="Edit Runtime"
      isLoading={isUpdateRuntimeLoading}
      primaryButtonText="Save"
    >
      <AFTextField name="name" label={<RequiredLabel value="Name" />} />
      <AFTextField
        name="awsClusterId"
        label={<RequiredLabel value="Cluster ID" />}
        disabled={isManagedByPlatform}
      />
      <AFCheckbox
        name="managedByPlatform"
        label="Yes"
        legend="Managed by Platform"
        onChange={(e) => setIsManagedByPlatform(e.target.checked)}
      />
      <AFDropdown
        options={clusterSizes}
        name="clusterSize"
        label={<RequiredLabel value="EMR Cluster Size" />}
        disabled={!isManagedByPlatform}
      />
    </AFFormModal>
  );
}

export default RuntimeEditModal;
