import React from "react";
import {
  AFCheckbox,
  AFDropdown,
  AFForm,
  AFRadioButtonGroup,
  AFTextArea,
  AFTextField,
} from "sharedComponents/Form";
import { Close } from "@carbon/react/icons";
import { Button, IconButton, Column } from "@carbon/react";
import JsonEditor from "sharedComponents/Form/JsonEditor";
// import { useUpdateWorkbookDetail } from "hooks/ruleworkbook/useUpdateWorkbookDetail";

const AddNewNodeForm = ({
  onClickSideNavExpand,
  setIsSideNavExpanded,
  activeNodeData,
  isEditNode = false,
}) => {
//   const { mutate: updateWorkbookDetail } = useUpdateWorkbookDetail(
//     activeNodeData.id
//   );

  const [selectedType, setSelectedType] = React.useState("json");
  const nodeTypeValues = [
    { id: 1, value: "transformation", labelText: "Transformation" },
    { id: 2, value: "validation", labelText: "Validation" },
  ];

  const typeValues = [
    { id: 1, value: "json", labelText: "JSON Rule" },
    { id: 2, value: "sql", labelText: "SQL" },
    { id: 3, value: "python", labelText: "Python" },
  ];

  const dataLevelType = [
    { id: 1, value: "Gold", labelText: "Gold" },
    { id: 2, value: "Silver", labelText: "Silver" },
    { id: 3, value: "Sandbox", labelText: "Sandbox" },
  ];

  const dataLevelOptions = dataLevelType.map((data) => ({
    value: data.id,
    label: data.labelText,
  }));

  const initialValues = {
    node_name: activeNodeData?.name || "",
    nodeType: "transformation",
    nodeDescription: activeNodeData?.description || "",
    type: "json",
    ruleData: activeNodeData?.ruleData || "",
    dataLevel: activeNodeData?.type || "",
    persistState: activeNodeData?.savedData || false,
  };
  const handleEditor = (value) => {
    console.log("Updated File:", value);
  };

  const handleSubmit = (value) => {
    console.log("Form Values:", value);
    setIsSideNavExpanded(false);
  };

  const handleValueChange = (values) => {
//     const nodeData = {
//       ruleType: values?.ruleType,
//       savedData: values?.persistState,
//       ruleData: values?.ruleData,
//       dataLevelTypeId: values?.dataLevel,
//       dataClassId: values?.dataLevel,
//     };

    // if (isEditNode) {
    //   updateWorkbookDetail(nodeData);
    // }
    setSelectedType(values);
  };

  return (
    <div className="workbookDrawerModal">
      <AFForm initialValues={initialValues} onSubmit={handleSubmit}>
        <Column
          span={16}
          className="flex_between"
          style={{ padding: "10px 0px" }}
        >
          <h3>New Node</h3>
          <IconButton
            kind="ghost"
            onClick={
              isEditNode
                ? () => setIsSideNavExpanded(false)
                : onClickSideNavExpand
            }
            label="Close"
          >
            <Close />
          </IconButton>
        </Column>

        <AFTextField name="node_name" label="Node Name" />
        <AFRadioButtonGroup
          name="nodeType"
          label="Node Type"
          options={nodeTypeValues}
          orientation={"horizontal"}
        />
        <AFTextArea
          name="nodeDescription"
          label="Node Description"
          enableCounter={true}
        />
        <AFRadioButtonGroup
          name="type"
          label="Type"
          options={typeValues}
          orientation={"horizontal"}
          handleValueChange={handleValueChange}
        />
        {selectedType === "json" ? (
          <JsonEditor
            name={"ruleData"}
            value={JSON.stringify(initialValues.ruleData, null, 2)}
            onChange={(value) => handleEditor(value)}
            accept={[".json"]}
            label={
              <>
                <p className="cds--file--label">Json rule</p>
                <p className="cds--label-description">
                  Max file size is 500kb. Supported file types are .json
                </p>
              </>
            }
          />
        ) : selectedType === "sql" ? (
          <AFTextArea name={"ruleData"} label={"SQL"} enableCounter={false} />
        ) : (
          <JsonEditor
            name={"ruleData"}
            value={JSON.stringify(initialValues.ruleData, null, 2)}
            onChange={(value) => handleEditor(value)}
            accept={[".py"]}
            label={
              <>
                <p className="cds--file--label">Python</p>
                <p className="cds--label-description">
                  Max file size is 500kb. Supported file types are .py
                </p>
              </>
            }
          />
        )}
        <AFDropdown
          label="Data Level"
          name="dataLevel"
          options={dataLevelOptions}
        />
        <AFCheckbox name={"persistState"} label={"Persist State"} />
        <Column span={16} className="flex_between">
          <Button
            kind="ghost"
            onClick={
              isEditNode
                ? () => setIsSideNavExpanded(false)
                : onClickSideNavExpand
            }
          >
            Cancel
          </Button>
          <Button type="submit">{isEditNode ? "Update" : "Create"}</Button>
        </Column>
      </AFForm>
    </div>
  );
};

export default AddNewNodeForm;
