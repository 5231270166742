import React from "react";
import TreeChart from "./TreeChart";

const height = 33;
const width = 150;
const typeArray = {
  Sandbox: "Sandbox",
  Silver: "Silver",
  Gold: "Gold",
};

const generateNodes = (nodes) => {
  return nodes?.map((node) => {
    return {
      id: `${node.id}`,
      height: height,
      width: width,
      data: {
        title: node?.name || "- -",
        id: node.id,
        description: node?.description || "",
        // TODO: Change the type to be dynamic
        type: typeArray[
          node?.ruleData?.data_level_id === 4 ? "Gold" : "Silver"
        ],
      },
    };
  });
};

const generatelink = (children) => {
  return children?.map((data) => ({
    id: `${data?.id}`,
    from: `${data?.source}`,
    to: `${data?.target}`,
  }));
};

function WorkbookChart({
  dataPackages,
  nodeData = [],
  edgeData = [],
  activeWorkbook,
}) {
  const nodesElement = React.useMemo(() => generateNodes(nodeData), [nodeData]);

  const linkElements = React.useMemo(() => generatelink(edgeData), [edgeData]);

  return (
    <TreeChart
      dataPackages={dataPackages}
      nodes={nodesElement}
      edges={linkElements}
      activeWorkbook={activeWorkbook}
    />
  );
}

export default WorkbookChart;
